<template>
  <div class="container privacy-policy-container m-b-110 pt-privacy-policy-title">
    <div class="card p-1 text-first border-0">
      <div class="card-body mx-auto">
        <div v-if="$i18n.locale ==='en'" class="privacy-en m-t-20">
          <h2 class="mt-0 mt-sm-4 mb-4 fw-bold font-30">Public Interest Incorporated Foundation Japan Tennis
            Association</h2>
          <h2 class="mt-0 mt-sm-4 mb-4 fw-bold font-30">Regulations on the Protection of Personal Information</h2>

          <h3 class="text-start">Chapter 1 General Provisions</h3>
          <h3 class="font-20"> (Objectives) </h3>
          <h3 class="font-16">Article 1</h3>

          These regulations shall stipulate the terms in protecting personal information as regulated in Article 71
          Paragraph 2 of the articles of association for Public Interest Incorporated Foundation Japan Tennis
          Association (hereinafter referred to as “JTA”).
          <h3 class="font-20">(Definitions)</h3>
          <h3 class="font-16">Article 2 </h3>
          The definitions of the words and terms used in these regulations shall be as stated in each of the following
          items:

          <ol class="li-number">
            <li>Personal Information is information relating to a living individual that can identify a certain
              individual by the name, date of birth, and other descriptions, etc. included in the said information
              (includes other information that can easily compare with and can identify a certain individual).
            </li>
            <li>A Person is a certain individual that is identifiable by the Personal Information.
            </li>
            <li>Staff are persons who handle the Personal Information (including officers, contract workers, part-time
              staff, short-term temporary staff, etc.).
            </li>
            <li>Management System for Protecting Personal Information is a system within JTA including policies,
              organization, plans, auditing, and revisions that protects the Personal Information kept by JTA.
            </li>
            <li>Chief Privacy Officer is a person who is responsible and has the right to implement and operate the
              Management System for Protecting Personal Information.
            </li>
            <li>Audit Manager is a person assigned by the chairperson and has the responsibility and right to take an
              objective position to audit and report.
            </li>
            <li>Education Manager is a person who is responsible and has the right to provide education and enlightening
              staff to understand the importance of the Management System for Protecting Personal Information.
            </li>
            <li>Usage is processing Personal Information within JTA.
            </li>
            <li>Provision is to have parties other than JTA use the Personal Information kept by JTA.</li>
          </ol>
          <h3 class="font-20">(Application)</h3>
          <h3 class="font-16">Article 3 </h3>
          These regulations apply to the Staff at JTA.<br>
          2. Personal Information must be protected appropriately according to the objectives of these regulations when
          outsourcing work that handle Personal Information.

          <h3 class="text-start">Chapter 2 Collection of Personal Information </h3>
          <h3 class="font-20">(General Rules in Collecting Personal Information)</h3>
          <h3 class="font-16">Article 4  </h3>
          When collecting Personal information, the usage objectives must be clarified and shall be to the extent
          necessary to fulfill the objectives.<br>
          2. Personal Information shall be collected in a lawful and fair manner.
          <h3 class="font-20">(Collection and Using Specific and Sensitive Personal Information and Restrictions in the Provision to Third Parties)</h3>
          <h3 class="font-16">Article 5 </h3>
          The following specific and sensitive Personal Information shall not be collected, used, or provided to third
          parties. However, unless it is based on laws and ordinances, and with the agreement of the Person, this is not
          limited to the extent necessary for our operation.

          <ol class="li-number">
            <li>Items related to thoughts, beliefs, and religion
            </li>
            <li>Items of race, ethnic group, lineage, hometown (excluding information related to prefectures) physical
              and mental disabilities, criminal records, and other information that may cause social discrimination
            </li>
            <li>Items regarding worker’s right to organize, collective bargaining, and other action groups
            </li>
            <li>Items regarding participating in demonstrations, exercising the right to petition, and exercising other
              political rights
            </li>
            <li>Health care and sex life</li>
          </ol>
          <h3 class="font-20">(Acquisition Procedures)</h3>
          <h3 class="font-16">Article 6 </h3>
          Approval from the Chief Privacy Officer must be obtained in advance after submitting the usage objectives and
          the implementation when collecting new Personal Information for the course of business operations.
          <h3 class="font-20">(Measures in Directly Acquiring Personal Information)</h3>
          <h3 class="font-16">Article 7</h3>
          When acquiring Personal Information directly from the Person, consent from the Person must be obtained by
          notifying each of the following items to the Person in writing or by an equivalent method.


          <ol class="li-number">
            <li>The acquisition of Personal Information and the concrete usage objectives
            </li>
            <li>When the Personal Information may be used, the actual objectives, the receiver of the said information,
              or the type and attributes of the organization of the receiver
            </li>
            <li>That the handling of Personal Information shall be outsourced
            </li>
            <li>The Provision of Personal Information shall be under one’s own accord and the consequences derived when
              the Person does not provide the said information
            </li>
            <li>When the said information is inaccurate under the right to disclose Personal Information and as a result
              of the disclosure, the right to correct or delete the said incorrect information and the procedures to
              execute the said rights
            </li>
          </ol>
          <h3 class="font-20" >(Measures when Indirectly Acquiring Personal Information from Other Parties) </h3>
          <h3 class="font-16">Article 8 </h3>
          When acquiring Personal Information indirectly from other parties, consent from the Person must be obtained by
          notifying Item 1 or Item 3 and Item 5 in the previous article in writing or by an equivalent method. However,
          this shall not apply for the following items:

          <ol class="li-number">
            <li>When acquiring from a party that has the consent of the Person according to Item 2 in the previous
              article
            </li>
            <li>When the handling of Personal Information is outsourced
            </li>
            <li>When it is unlikely that undue harm shall be caused to the interests that merits the protection of the
              Person
            </li>
          </ol>

          <h3 class="text-start">Chapter 3 Transferring and Sending Personal Information</h3>
          <h3 class="font-20">(General Rules in Transferring and Sending Personal Information)</h3>
          <h3 class="font-16">Article 9  </h3>
          Only the party who has the personal rights shall be able to transfer and send Personal Information by a
          required and appropriate procedure to prevent the danger of leakage, etc. to the extent necessary to perform
          the task.<br>

          2. When transferring and sending Personal Information through the internet, strict security measures such as
          encrypting the said Personal Information or restricting the access with passwords, etc. must be taken. The
          Personal Information must be confirmed that it has been transferred or sent to the said address.<br>

          3. When transferring the Personal Information to information system hardware or to a storage medium in the
          said hardware, strict security measures must be taken such as encrypting the said Personal Information or
          restricting the access with passwords, etc.

          <h3 class="text-start">Chapter 4 Use of Personal Information </h3>
          <h3 class="font-20">(General Rules in the Use of Personal Information)</h3>
          <h3 class="font-16">Article 10 </h3>
          Personal Information shall be used, in principle, within the usage objectives and only the party with personal
          rights shall be able to use the information to the extent necessary to perform the task.
          <h3 class="font-20">(Use of Personal Information Other Than the Objectives)</h3>
          <h3 class="font-16">Article 11 </h3>
          When using Personal Information exceeding the scope of the usage objectives, consent from the Person must be
          obtained in advance after notifying the Person in writing or an equivalent method when applying Item 1 or Item
          3 and Item 5 in Article 7.<br>
          2. The approval of the Chief Privacy Officer must be obtained when requesting consent of the Person to use
          Personal Information that exceeds the scope of the usage objectives.
          <h3 class="font-20">(Shared Use of Personal Information)</h3>
          <h3 class="font-16">Article 12</h3>
          The approval of the Chief Privacy Officer must be obtained when providing or sharing the use of Personal
          Information to a third party.
          <h3 class="font-20">(Outsourcing the Handling of Personal Information)</h3>
          <h3 class="font-16">Article 13</h3>
          The approval of the Chief Privacy Officer must be obtained when outsourcing the handling of Personal
          Information to a third party.

          <h3 class="text-start">Chapter 5 Provision of Personal Information to Third Parties </h3>
          <h3 class="font-20">(General Rules in Providing Personal Information to Third Parties)</h3>
          <h3 class="font-16">Article 14 </h3>
          Personal Information should not be provided to third parties without the advanced consent of the Person.<br>
          2. In case of providing Personal Information to a third party, notify the Person in writing or an equivalent
          method as stipulated in Article 7 Item 1, Item 2, and Item 5 and receive consent from the Person.<br>
          3. Approval from the Chief Privacy Officer must be obtained in case of providing Personal Information to a
          third party based on the previous paragraph.

          <h3 class="text-start">Chapter 6 Management of Personal Information </h3>
          <h3 class="font-20">(General Rules on Managing Personal Information)</h3>
          <h3 class="font-16">Article 15</h3>
          Personal Information must be managed in an accurate and most recent condition when using within the scope
          necessary to achieve the purposes of use.
          <h3 class="font-20">(Safety Management Measures for Personal Information)</h3>
          <h3 class="font-16">Article 16 </h3>
          The Chief Privacy Officer must take necessary and appropriate safety management measures to respond to risks
          regarding Personal Information (unauthorized access to Personal Information, loss, damage, falsification,
          leakage, etc. of Personal Information).<br>
          2. Personal Information must be kept in a locked location and the keys must be kept by the Chief Privacy
          Officer or a person who is permitted to use the said Personal Information.<br>
          3. The hardware storing the Personal Information must have appropriate access restrictions such as IDs,
          passwords, etc.<br>
          4. The access records to the information system where the Personal Information is stored must be for a
          reasonable period.<br>
          5. The Chief Privacy Officer must manage and create a ledger with the collected items, usage objectives,
          storage period, storage method, disposal procedures, etc. of all the Personal Information kept by JTA and must
          update the ledger more than once in each fiscal year.

          <h3 class="text-start">Chapter 7 Disclosure, Amendment, Addition, Termination, Usage, and Deleting Personal
            Information </h3>
          <h3 class="font-20">(Rights Regarding Self-information)</h3>
          <h3 class="font-16">Article 17</h3>
          Information can be disclosed under the request of the Person if it is within a reasonable period.<br>
          2. As a result of the disclosure in the previous paragraph, if there are requests to correct, add or delete
          the information in case it is incorrect, in principle, JTA must respond within a reasonable period. When
          correcting, adding, or deleting the information, a notice must be sent to the receiver of the said Personal
          Information to the extent possible.
          <h3 class="font-20">(Refusal to Use or Provide Self-information)</h3>
          <h3 class="font-16">Article 18 </h3>
          Personal Information shall not be provided to a third party in case the Person refuses to use or provide their
          information to a third party unless the Usage or Provision is based on laws and ordinances.

          <h3 class="text-start">Chapter 8 Deleting and Discarding Personal Information</h3>
          <h3 class="font-20">(Procedures in Deleting and Discarding) </h3>
          <h3 class="font-16">Article 19</h3>
          After the usage objectives of the said Personal Information has terminated, only an authoritative person shall
          be able to delete or discard Personal Information within a reasonable period by using appropriate methods such
          as physically destroying, etc. the recorded media to prevent external leakage, etc.

          <h3 class="text-start">Chapter 9 Organization and Structure</h3>
          <h3 class="font-20">(Chief Privacy Officer)</h3>
          <h3 class="font-16">Article 20 </h3>
          The chairperson shall assign the administrative officer to be the Chief Privacy Officer to manage Personal
          Information for JTA.<br>
          2. The Chief Privacy Officer shall, based on the orders of the chairperson and the stipulations of these
          regulations, establish the inner regulations regarding the protection of Personal Information, enact safety
          measures, formulate management systems to protect Personal Information by promoting education and training,
          etc. and shall be responsible to implement measures to thoroughly publicize etc. the matter.
          <h3 class="font-20">(Education)</h3>
          <h3 class="font-16">Article 21 </h3>
          The Chief Privacy Officer shall also hold the post of Education Manager, and to ensure successful
          implementation shall continuously and periodically educate and enlighten the Staff to understand the
          importance of the Management System for Protecting Personal Information.
          <h3 class="font-20">(Auditing)</h3>
          <h3 class="font-16">Article 22</h3>
          The chairperson shall assign the managing director to be the Audit Manager who shall audit that the protection
          of Personal Information is appropriately performed according to the management system.<br>
          2. The Audit Manager shall write up an audit report based on the auditing results and shall report to the
          chairperson.<br>
          3. The chairperson shall give improvement instructions to the Chief Privacy Officer and related parties in
          case there are infringements to the Management System for Protecting Personal Information based on the
          management of Personal Information by JTA.<br>
          4. The party receiving the improvement instructions, based on the previous paragraph, shall promptly perform
          appropriate improvement measures, and shall report the content to the Audit Manager.<br>
          5. The Audit Manager shall evaluate the improvement measures implemented according to the previous paragraph
          and shall report to the chairperson and the Chief Privacy Officer.
          <h3 class="font-20">(Duty to Report and Penalties)</h3>
          <h3 class="font-16">Article 23</h3>
          Parties who discover the fact that the Management System for Protecting Personal Information was infringed or
          may be infringed, shall report the fact to the Chief Privacy Officer.<br>
          2. The Chief Privacy Officer shall investigate the content of the report according to the previous paragraph
          and shall report to the chairperson in case the infringement was determined and shall give instructions to
          take appropriate procedures to the related departments.
          <h3 class="font-20">(Complaints and Consultation)</h3>
          <h3 class="font-16">Article 24</h3>
          The Chief Privacy Officer shall set up consulting services to receive and respond to complaints and give
          advice to Persons regarding Personal Information and the Management System for Protecting Personal
          Information.

          <h3 class="text-start">Chapter 10 Miscellaneous Rules </h3>
          <h3 class="font-20">(Review)</h3>
          <h3 class="font-16">Article 25</h3>
          The chairperson shall give instructions to the Chief Privacy Officer to consider reviewing the management
          system including the revision and abolition of these regulations to maintain appropriate protection of
          Personal Information by comparing with the auditing report.

          <h3 class="font-20">(Bylaws)</h3>
          <h3 class="font-16">Article 26</h3>
          In case JTA outsources the handling of Personal Information kept by JTA to a third party, the regulations and
          regulations regarding the education, disclosure, auditing of the protection of Personal Information and
          necessary items regarding the enforcement of these regulations shall be based on separate bylaws.

          <h3 class="font-20">(Management of the Management System for Protecting Personal Information)</h3>
          <h3 class="font-16">Article 27 </h3>
          When reviewing the Management System for Protecting Personal Information, the said content shall be recorded
          and managed on a revision history.
          <h3 class="font-20">(Revision and Abolition)</h3>
          <h3 class="font-16">Article 28</h3>
          The revision and abolition of these regulations shall be performed after a board resolution.

          Supplementary Provisions
          These regulations shall be enforced on the transition registration date to the public interested incorporated
          foundation.

        </div>
        <div v-if="$i18n.locale ==='ja'" class="row privacy-ja update-btn-div">
          <h2 class="mt-0 mt-sm-4 mb-4 fw-bold font-30">公益財団法人 日本テニス協会 個人情報保護規程</h2>
          <div class="col">
            <div class="row">
              <h3>第1章 総則</h3>
            </div>
            <div class="row"><h5>(目的)</h5></div>
            <div class="row">
              <div><h6>第1条</h6></div>
              <div>
                公益財団法人日本テニス協会(以下「本協会」という。)定款第 71 条第 2 項に規定する個人情報の保護に関する事項は、この規程の定めるところによる。
              </div>
            </div>
            <div class="row"><h5>(定義)</h5></div>
            <div class="row">
              <div><h6>第2条</h6></div>
              <div>
                本規程における用語の定義は、次の各号に定めるところによる。
              </div>
              <div>
                <ol>
                  <li>個人情報<br/>生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの(他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含む)
                  </li>
                  <li>本人<br/>個人情報によって識別される特定の個人
                  </li>
                  <li>従業者<br/>本会の組織内で指揮監督を受け、個人情報の取扱いに従事する者(役員、派遣職員、パート職員、アルバイト等を含む)
                  </li>
                  <li>個人情報保護マネジメントシステム<br/>本会が保有する個人情報を保護するための方針、組織、計画、監査、及び見直しを含む本会内のしくみのすべて
                  </li>
                  <li>個人情報保護管理者<br/>会長より任命され、個人情報保護マネジメントシステムの実施及び運用に関する責任と権限を有する者
                  </li>
                  <li>監査責任者<br/>会長より任命され、客観的な立場により監査の実施及び報告を行う責任と権限を有する者
                  </li>
                  <li>教育責任者<br/>個人情報保護マネジメントシステムの重要性を理解させるための教育・啓発を行う責任と権限を有する者
                  </li>
                  <li>利用<br/>本会内において個人情報を処理すること
                  </li>
                  <li>提供<br/>本会以外の者に、本会の保有する個人情報を利用可能にすること
                  </li>
                </ol>
              </div>
            </div>
            <div class="row"><h5>(適用範囲)</h5></div>
            <div class="row">
              <div><h6>第3条</h6></div>
              <div>
                <ol>
                  <li class="li-list-style_none">本規程は、本会の従業者に対して適用する。</li>
                  <li value="2">個人情報を取扱う業務を外部に委託する場合も、この規程の目的に従って、個人情報の適正な保護を図る。</li>
                </ol>
              </div>
            </div>

            <div class="row">
              <h3>第2章 個人情報の取得</h3>
            </div>
            <div class="row"><h5>(個人情報の取得の原則)</h5></div>
            <div class="row">
              <div><h6>第4条</h6></div>
              <div>
                <ol>
                  <li class="li-list-style_none">個人情報の取得は、利用目的を明確に定め、その目的の達成のために必要な限度においてのみ行う。</li>
                  <li value="2">個人情報の取得は、適法かつ公正な方法により行う。</li>
                </ol>
              </div>
            </div>
            <div class="row"><h5>(特定の機微な個人情報の取得・利用・第三者提供の禁止)</h5></div>
            <div class="row">
              <div><h6>第5条</h6></div>
              <div>
                次の各号に掲げる特定の機微な個人情報については、これを取得し、利用又は第三者に提供してはならない。ただし、法令に基づく場合および本人の同意があり、かつ業務遂行上必要な範囲においてはこの限りではない。
              </div>
              <div>
                <ol>
                  <li>思想、信条及び宗教に関する事項</li>
                  <li>人種、民族、門地、本籍地(所在都道府県に関する情報を除く)、身体・精神障害、犯罪歴、その他社会的差別の原因となる事項</li>
                  <li>勤労者の団結権、団体交渉及びその他団体行動の行為に関する事項</li>
                  <li>集団示威行為への参加、請願権の行使、及びその他の政治的権利の行使に関する事項</li>
                  <li>保健医療及び性生活</li>
                </ol>
              </div>
            </div>
            <div class="row"><h5>(取得の手続)</h5></div>
            <div class="row">
              <div><h6>第6条</h6></div>
              <div>
                業務において新たに個人情報を取得する場合には、あらかじめ、個人情報保護管理者に利用目的及び実施方法を届け出、承認を得る。
              </div>
            </div>
            <div class="row"><h5>(本人から直接に個人情報を取得する場合の措置)</h5></div>
            <div class="row">
              <div><h6>第7条</h6></div>
              <div>
                本人から直接に個人情報を取得する場合は、本人に対して、次の各号に掲げる事項を書面またはこれに準ずる方法によって通知し、本人の同意を得る。
              </div>
              <div>
                <ol>
                  <li>個人情報の取得及び利用の具体的な目的</li>
                  <li>個人情報の提供を行うことが予定されている場合は、その具体的な目的、当該情報の受領者または受領者の組織の種類、属性</li>
                  <li>個人情報の取扱いを委託することが予定されている場合は、その旨</li>
                  <li>個人情報を与えることは、本人の任意であること、及び当該情報を与えなかった場合における本人に生じる結果</li>
                  <li>個人情報の開示を求める権利、及び開示の結果、当該情報が誤っている場合に訂正又は削除を要求する権利の存在、並びに当該権利を行使するための手続き</li>
                </ol>
              </div>
            </div>
            <div class="row"><h5>(本人以外から間接に個人情報を取得する場合の措置)</h5></div>
            <div class="row">
              <div><h6>第8条</h6></div>
              <div>
                本人以外から間接に個人情報を取得する場合は、前条第1号ないし第3号及び第5号に掲げる事項を書面又はこれに準ずる方法によって通知し、本人の同意を得る。ただし、次の各号に該当する場合は、この限りでない。
              </div>
              <div>
                <ol>
                  <li>前条第2号に従って、本人の同意を得ている者から取得する場合</li>
                  <li>個人情報の取扱いを委託される場合</li>
                  <li>本人の保護に値する利益が侵害されるおそれのない場合</li>
                </ol>
              </div>
            </div>

            <div class="row">
              <h3>第3章 個人情報の移送・送信</h3>
            </div>
            <div class="row"><h5>(個人情報の移送・送信の原則)</h5></div>
            <div class="row">
              <div><h6>第9条</h6></div>
              <div>
                <ol>
                  <li class="li-list-style_none">
                    個人情報の移送・送信は、具体的な権限を与えられた者のみが、外部流出等の危険を防止するため必要かつ適切な方法により、業務の遂行上必要な限りにおいて行う。
                  </li>
                  <li value="2">
                    個人情報の移送・送信をインターネットに介して行う場合は、当該個人情報を暗号化又はパスワードによるアクセス制限を行う等、厳格なセキュリティ対策を講じるとともに、移送・送信先の確認を徹底する。
                  </li>
                  <li>個人情報を情報システム関連機器又は当該機器に関連する記憶媒体を用いて移送する場合は、当該個人情報を暗号化又はパスワードによるアクセス制限を行う等、厳格なセキュリティ対策を講じる。</li>
                </ol>
              </div>
            </div>

            <div class="row">
              <h3>第4章 個人情報の利用</h3>
            </div>
            <div class="row"><h5>(個人情報の利用の原則)</h5></div>
            <div class="row">
              <div><h6>第10条</h6></div>
              <div>個人情報は、原則として、利用目的の範囲内で、具体的な権限を与えられた者のみが、業務の遂行上必要な限りにおいて利用できる。
              </div>
            </div>
            <div class="row"><h5>(個人情報の目的外利用)</h5></div>
            <div class="row">
              <div><h6>第11条</h6></div>
              <div>
                <ol>
                  <li class="li-list-style_none">
                    利用目的の範囲を超えて個人情報を利用する場合は、第7条第1号ないし第3号及び第5号に掲げる事項を書面又はこれに準ずる方法により本人に通知して、事前の本人の同意を得る。
                  </li>
                  <li value="2">利用目的の範囲を超えて個人情報を利用するために本人の同意を求める場合は、個人情報保護管理者の承認を得て行う。</li>
                </ol>
              </div>
            </div>
            <div class="row"><h5>(個人情報の共同利用)</h5></div>
            <div class="row">
              <div><h6>第12条</h6></div>
              <div>
                個人情報を第三者へ提供又は共同利用する場合は、個人情報保護管理者の承認を得て行う。
              </div>
            </div>
            <div class="row"><h5>(個人情報の取扱いの委託)</h5></div>
            <div class="row">
              <div><h6>第13条</h6></div>
              <div>
                個人情報の取扱いを第三者に委託する場合は、個人情報保護管理者の承認を得て行う。
              </div>
            </div>

            <div class="row">
              <h3>第5章 個人情報の第三者提供</h3>
            </div>
            <div class="row"><h5>(個人情報の第三者提供の原則)</h5></div>
            <div class="row">
              <div><h6>第14条</h6></div>
              <div>
                <ol>
                  <li class="li-list-style_none">個人情報は、事前に本人の同意を得ることなく、第三者に提供してはならない。
                  </li>
                  <li value="2">個人情報を第三者に提供する場合は、第7条第1号、第2号及び第5号に掲げる事項を書面又はこれに準ずる方法によって通知し、本人の同意を得る。</li>
                  <li>前項に基づき個人情報を第三者に提供する場合は、個人情報保護管理者の承認を得て行う。</li>
                </ol>
              </div>
            </div>

            <div class="row">
              <h3>第6章 個人情報の管理</h3>
            </div>
            <div class="row"><h5>(個人情報の管理の原則)</h5></div>
            <div class="row">
              <div><h6>第15条</h6></div>
              <div>
                個人情報は、利用目的の達成に必要な範囲内において正確かつ最新の状態で管理する。
              </div>
            </div>
            <div class="row"><h5>(個人情報の安全管理対策)</h5></div>
            <div class="row">
              <div><h6>第16条</h6></div>
              <div>
                <ol>
                  <li class="li-list-style_none">
                    個人情報保護管理者は、個人情報に関するリスク(個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えいなど)に対して、必要かつ適切な安全管理対策を講じる。
                  </li>
                  <li value="2">個人情報は、施錠の可能な場所に保管し、鍵は、個人情報保護管理者又は当該個人情報の利用を許された者が保管する。</li>
                  <li>個人情報の保存されている端末には、ID 及びパスワード等適切なアクセス制限を施す。</li>
                  <li>個人情報の保存されている情報システムへのアクセス記録は、合理的な期間これを保存する。</li>
                  <li>個人情報保護管理者は、本会が保有する全ての個人情報の収集項目、利用目的、保管期間、保管方法、破棄方法などを台帳として管理・作成し、各年度内に一度以上更新を行う。</li>
                </ol>
              </div>
            </div>

            <div class="row">
              <h3>第7章 個人情報の開示・訂正・追加・利用停止・削除</h3>
            </div>
            <div class="row"><h5>(自己情報に関する権利)</h5></div>
            <div class="row">
              <div><h6>第17条</h6></div>
              <div>
                <ol>
                  <li class="li-list-style_none">本人から自己の情報について開示を求められた場合、合理的な期間内にこれに応じる。</li>
                  <li value="2">
                    前項に基づく開示の結果、誤った情報があり、訂正、追加又は削除を求められた場合、原則として合理的な期間内にこれに応ずるとともに、訂正、追加又は削除を行った場合、可能な範囲内で当該個人情報の受領者に対して通知を行う。
                  </li>
                </ol>
              </div>
            </div>
            <div class="row"><h5>(自己情報の利用又は提供の拒否)</h5></div>
            <div class="row">
              <div><h6>第18条</h6></div>
              <div>本人から自己の情報について利用又は第三者の提供を拒否された場合は、これに応じるものとする。ただし、法令に基づく場合は、この限りでない。
              </div>
            </div>

            <div class="row">
              <h3>第8章 個人情報の消去・廃棄</h3>
            </div>
            <div class="row"><h5>(消去・廃棄の手続)</h5></div>
            <div class="row">
              <div><h6>第19条</h6></div>
              <div>
                個人情報の消去及び廃棄は、当該個人情報の利用目的が終了した後、合理的な期間内に、具体的な権限を与えられた者のみが、外部流出等の危険を防止するため、記憶媒体を物理的に破壊するなど適切な方法によりそれを行う。
              </div>
            </div>

            <div class="row">
              <h3>第9章 組織及び体制</h3>
            </div>
            <div class="row"><h5>(個人情報保護管理者)</h5></div>
            <div class="row">
              <div><h6>第20条</h6></div>
              <div>
                <ol>
                  <li class="li-list-style_none">会長は、事務局長を個人情報保護管理者として任命し、本会内における個人情報の管理業務を行わせる。</li>
                  <li value="2">
                    個人情報保護管理者は、会長の指示及び本規程に定めるところに基づき、個人情報保護に関する内部規程の整備、安全対策の実施、教育訓練等を推進するための個人情報保護マネジメントシステムを策定し、周知徹底等の措置を実践する責任を負う。
                  </li>
                </ol>
              </div>
            </div>
            <div class="row"><h5>(教育)</h5></div>
            <div class="row">
              <div><h6>第21条</h6></div>
              <div>個人情報保護管理者は、教育責任者を兼任するものとし、従業者に対して個人情報保護マネジメントシステムの重要性を理解させ、確実な実施を図るため、継続的かつ定期的に教育・啓発を行う。
              </div>
            </div>
            <div class="row"><h5>(監査)</h5></div>
            <div class="row">
              <div><h6>第22条</h6></div>
              <div>
                <ol>
                  <li class="li-list-style_none">
                    会長は、専務理事を監査責任者に任命し、本会内における個人情報の管理が個人情報保護マネジメントシステムに従い適正に実施されているかにつき監査を行わせる。
                  </li>
                  <li value="2">監査責任者は、監査の結果につき監査報告書を作成し、会長に対して報告を行う。</li>
                  <li>会長は、本会内における個人情報の管理につき個人情報保護マネジメントシステムに違反する行為があった場合には、個人情報保護管理者及び関係者に対し改善指示を行う。</li>
                  <li>前項に基づき改善指示を受けた者は、速やかに適正な改善措置を講じ、その内容を監査責任者に報告する。</li>
                  <li>監査責任者は、前項によりなされた改善措置を評価し、会長及び個人情報保護管理者に対して報告する。</li>
                </ol>
              </div>
            </div>
            <div class="row"><h5>(報告義務及び罰則)</h5></div>
            <div class="row">
              <div><h6>第23条</h6></div>
              <div>
                <ol>
                  <li class="li-list-style_none">個人情報保護マネジメントシステムに違反する事実又は違反するおそれがあることを発見した者は、その旨を個人情報保護管理者に報告する。</li>
                  <li value="2">個人情報保護管理者は、前項による報告の内容を調査し、違反の事実が判明した場合には、遅滞なく会長に報告し、かつ関係部門に適切な処置を行うよう指示する。</li>
                </ol>
              </div>
            </div>
            <div class="row"><h5>(苦情及び相談)</h5></div>
            <div class="row">
              <div><h6>第24条</h6></div>
              <div>
                個人情報保護管理者は、相談窓口を設置し、個人情報及び個人情報保護マネジメントシステムに関して本人からの苦情及び相談を受け付けて対応する。
              </div>
            </div>

            <div class="row">
              <h3>第10章 雑則</h3>
            </div>
            <div class="row"><h5>(見直し)</h5></div>
            <div class="row">
              <div><h6>第25条</h6></div>
              <div>会長は、個人情報保護管理者に対し、監査報告書などに照らして適切な個人情報の保護を維持するために、本規程の改廃を含むマネジメントシステムの見直しの検討を指示する。
              </div>
            </div>
            <div class="row"><h5>(細則)</h5></div>
            <div class="row">
              <div><h6>第26条</h6></div>
              <div>本協会が保有する個人情報の取り扱いを第三者に委託する場合の規則及び個人情報保護の教育、開示、監査に関する規則並びに本規程の施行に関し必要な事項は、別に定める細則によるものとする。</div>
            </div>
            <div class="row"><h5>(個人情報保護マネジメントシステムの管理)</h5></div>
            <div class="row">
              <div><h6>第27条</h6></div>
              <div>個人情報保護マネジメントシステムの見直しを行った際は、当該内容を改定履歴に記載し、管理する。</div>
            </div>
            <div class="row"><h5>(改廃)</h5></div>
            <div class="row">
              <div><h6>第28条</h6></div>
              <div>この規程の改廃は、理事会の議決を経て行う。</div>
            </div>
            <div class="row mt-3"><h6>附則</h6></div>
            <div class="row">
              <div>1 この規程の改廃は、理事会の議決を経て行う。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
  }
}
</script>

<style scoped>
.privacy-policy-container {
  padding-left: 0;
  padding-right: 0;
  color: #333333;
}

h3 {
  margin-top: 20px;
}

h5 {
  margin-top: 15px;
}

h6 {
  margin-top: 5px;
}

ul {
  padding-left: 1rem;
}

li {
  /*list-style-type: none;*/
}

.li-list-style_none {
  list-style-type: none;
}

.tournament-info-breadcrumb a {
  color: #333333;
}

.update-btn-div {
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {

  ul {
    padding-left: 0.5rem;
  }

  li p {
    line-height: 20px;
  }

}

@media only screen and (max-width: 576px) {

  .card {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
  }

  .pt-privacy-policy-title {
    padding-top: 0.6rem;
  }

  .privacy-policy-container {
    margin-top: -50px;
    font-size: 13px;
  }

}
</style>